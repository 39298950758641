import "core-js/modules/es.array.push.js";
import common from '@mixins/common';
import { getDetailById, getSpaceList, fileUpload, getQuestionCategoryList, createOrder, cancelOrder, getShouFeiList } from '@api/maintenance';
import { getStorage } from '@utils/storage.js';
import { mapGetters } from 'vuex';
import { ImagePreview } from 'vant';
export default {
  name: 'DirectionsList',
  mixins: [common],
  data() {
    return {
      headerTitle: '发起工单',
      userInfo: JSON.parse(getStorage('user_info', 'localStorage')),
      // 空间一级分类
      fieldNames: {
        text: 'spaceName',
        value: 'spaceId',
        children: 'children'
      },
      questionfieldNames: {
        text: 'name',
        value: 'id',
        children: 'children'
      },
      // 层级
      showSpace: false,
      showQuestion: false,
      showDatePicker: false,
      spaceList: [],
      questionList: [],
      expectRepairDate: new Date(),
      shouFeiList: [],
      shouFeiAllList: [],
      form: {
        spaceName: null,
        spaceId: null,
        questionDes: null,
        questionCategoryId: null,
        questionCategoryName: null,
        expectRepairDate: null,
        expectRepairTimeSpan: null,
        contactsName: null,
        contactsMobile: null,
        contactsRemark: null,
        questionDesImgList: []
      },
      // 取消工单
      showReasonOrder: false,
      orderReasonValue: ''
    };
  },
  props: {},
  computed: {
    ...mapGetters('user', ['projectInfo'])
  },
  created() {
    const {
      token,
      projectId,
      userId,
      userName
    } = this.$route.query;
    if (token && projectId) {
      this.$store.commit('user/SET_TOKEN', token);
      this.$store.commit('user/SET_USER_INFO', {
        dcProjectId: projectId,
        userId,
        userName
      });
      this.$store.commit('user/SET_PROJECT_INFO', {
        dcProjectId: projectId
      });
    }
    console.log(this.projectInfo, this.$route.query.id);
    this.getDetail();
    this.getSpaceList();
    this.getQusitonList();
    this.getShouFeiList();
  },
  mounted() {},
  methods: {
    getDetail() {
      this.$store.dispatch('base/SetLoading', true);
      getDetailById({
        id: this.$route.query.id
      }).then(res => {
        const cb = res.data;
        if (cb.code === 200) {
          console.log(cb.data, '详情数据');
          this.form = cb.data;
        }
      }).finally(() => {
        this.$store.dispatch('base/SetLoading', false);
      });
    },
    // 空间层级
    getSpaceList() {
      getSpaceList({
        projectId: this.projectInfo.dcProjectId
      }).then(res => {
        let data = res.data.data;
        this.spaceList = this.listToTree(data);
        console.log(this.spaceList);
      });
    },
    listToTree(oldArr) {
      oldArr.forEach(element => {
        const parentId = element.parentId;
        if (parentId !== 0) {
          oldArr.forEach(ele => {
            if (ele.spaceId === parentId) {
              if (!ele.children) {
                ele.children = [];
              }
              ele.children.push(element);
            }
          });
        }
      });
      // console.log('1711', oldArr);
      // 清除重复的
      oldArr = oldArr.filter(ele => ele.parentId === '0');
      oldArr = this.arrSort(oldArr);
      return oldArr;
    },
    getShouFeiList() {
      let {
        id
      } = this.$route.query;
      getShouFeiList({
        orderId: id
      }).then(res => {
        let result = res.data;
        if (result.code == 200) {
          this.shouFeiList = result.result || [];
          let shouFeiAllList = [];
          this.shouFeiList.forEach(i => {
            shouFeiAllList.push(...i.shouFeiList);
          });
          this.shouFeiAllList = shouFeiAllList.filter(i => i.total != 0);
        }
      });
    },
    disposeTotalShouFei() {
      let sum = 0;
      this.shouFeiAllList.forEach(i => {
        sum += Number(i.price) * Number(i.total);
      });
      return sum;
    },
    arrSort(arr) {
      const newArr = arr.sort((a, b) => {
        return a.orderNum - b.orderNum;
      });
      newArr.map(item => {
        if (item.children && item.children.length > 0) {
          item.children = this.arrSort(item.children);
        }
      });
      return newArr;
    },
    spaceChange(e) {
      this.form.spaceName = e.selectedOptions[e.selectedOptions.length - 1].spaceFullName;
    },
    questionChange(e) {
      this.form.questionCategoryName = e.selectedOptions[e.selectedOptions.length - 1].name;
    },
    // 问题类型
    getQusitonList() {
      getQuestionCategoryList().then(res => {
        const data = res.data.data;
        if (data.code == 200) {}
        this.questionList = data;
      });
    },
    changeDate(e) {
      this.form.expectRepairDate = this.timeFormat(this.expectRepairDate);
      this.showDatePicker = false;
    },
    timeFormat(time) {
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let date = time.getDate();
      if (month < 10) month = '0' + month;
      if (date < 10) date = '0' + date;
      return year + '-' + month + '-' + date;
    },
    onSubmit() {
      let data = {
        ...this.form,
        projectId: this.projectInfo.dcProjectId
      };
      createOrder(data).then(res => {
        const cb = res.data;
        if (cb.code === 200) {
          this.$toast('创建成功');
        }
      });
    },
    // 图片预览
    reviewImg(imgList) {
      const result = imgList.map(item => {
        return item.url;
      });
      ImagePreview(result);
      // ImagePreview([img[0].fileUrl, img[1].fileUrl])
    },

    afterIDRead(file) {
      console.log(file);
      file.status = 'uploading';
      file.message = '上传中...';
      fileUpload(file).then(res => {
        const cb = res.data;
        if (cb.code === 200) {
          this.form.questionDesImgList = [{
            fileType: 1,
            url: cb.data.url,
            fileUrl: cb.data.url
          }];
        }
      });
    },
    onOversize() {
      this.$toast('文件大小不能超过5M');
    },
    // 取消工单
    handleShowReaseOrder() {
      this.orderReasonValue = '';
      this.showReasonOrder = true;
    },
    reasonBeforeClose(action, done) {
      if (action === 'confirm') {
        // if (this.orderReasonValue === '') {
        //   this.$toast('请填写原因')
        //   done(false)
        //   return
        // }

        cancelOrder({
          id: this.$route.query.id,
          closeDes: this.orderReasonValue
        }).then(res => {
          if (res.data.code === 200) {
            done();
            this.$toast('保存成功');
            this.getDetail();
          }
        }).catch(e => {
          done(false);
        });
      } else {
        done();
      }
    }
  },
  filters: {}
  //
};