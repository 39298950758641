var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-prompt"
  }, [_c('div', {
    staticClass: "cyui-prompt-header"
  }, [_c('div', {
    staticClass: "cyui-prompt-pic"
  }, [_vm._t("icon", function () {
    return [_vm.showIcon ? _c('svg-icon', {
      attrs: {
        "icon-class": _vm.icon
      }
    }) : _vm._e()];
  })], 2)]), _c('div', {
    staticClass: "cyui-prompt-body"
  }, [_c('div', {
    staticClass: "cyui-prompt-message"
  }, [_vm._t("title", function () {
    return [_vm.showTitle ? _c('p', {
      staticClass: "cyui-prompt-message-title"
    }, [_vm._v(_vm._s(_vm.title))]) : _vm._e()];
  }), _vm._t("text", function () {
    return [_vm.showMsg ? _c('p', [_vm._v(_vm._s(_vm.msg))]) : _vm._e()];
  })], 2), _c('div', {
    staticClass: "cyui-prompt-button"
  }, [_vm._t("btn")], 2)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };