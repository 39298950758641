var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.headerTitle
          },
          on: {
            "go-back": _vm.goBack,
            "on-close": _vm.handleClosePage
          },
          scopedSlots: _vm._u([{
            key: "headerRight",
            fn: function () {
              return [_c('div')];
            },
            proxy: true
          }], null, false, 378232108)
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "cyui-driections-navbar"
        }, [_c('van-form', {
          on: {
            "submit": _vm.onSubmit
          }
        }, [_c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "label": "工单编号",
            "input-align": "right"
          },
          model: {
            value: _vm.form.spaceId,
            callback: function ($$v) {
              _vm.$set(_vm.form, "spaceId", $$v);
            },
            expression: "form.spaceId"
          }
        }), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "label": "空间",
            "input-align": "right"
          },
          model: {
            value: _vm.form.spaceName,
            callback: function ($$v) {
              _vm.$set(_vm.form, "spaceName", $$v);
            },
            expression: "form.spaceName"
          }
        }), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "label": "问题类型",
            "input-align": "right"
          },
          model: {
            value: _vm.form.questionCategoryName,
            callback: function ($$v) {
              _vm.$set(_vm.form, "questionCategoryName", $$v);
            },
            expression: "form.questionCategoryName"
          }
        }), _vm.form.questionDes ? _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "rows": "3",
            "autosize": "",
            "maxlength": "100",
            "type": "textarea",
            "label": "问题描述",
            "input-align": "left"
          },
          model: {
            value: _vm.form.questionDes,
            callback: function ($$v) {
              _vm.$set(_vm.form, "questionDes", $$v);
            },
            expression: "form.questionDes"
          }
        }) : _vm._e(), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "label": "图片",
            "input-align": "left"
          },
          scopedSlots: _vm._u([{
            key: "input",
            fn: function () {
              return _vm._l(_vm.form.questionDesImgList, function (item, index) {
                return _c('img', {
                  key: index,
                  staticClass: "image",
                  attrs: {
                    "src": item.fileUrl,
                    "alt": ""
                  },
                  on: {
                    "click": function ($event) {
                      return _vm.reviewImg(_vm.form.questionDesImgList);
                    }
                  }
                });
              });
            },
            proxy: true
          }])
        }), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "label": "期望上门日期",
            "input-align": "right"
          },
          model: {
            value: _vm.form.expectRepairDate,
            callback: function ($$v) {
              _vm.$set(_vm.form, "expectRepairDate", $$v);
            },
            expression: "form.expectRepairDate"
          }
        }), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "label": "联系人",
            "input-align": "right"
          },
          model: {
            value: _vm.form.contactsName,
            callback: function ($$v) {
              _vm.$set(_vm.form, "contactsName", $$v);
            },
            expression: "form.contactsName"
          }
        }), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "label": "联系方式",
            "input-align": "right"
          },
          model: {
            value: _vm.form.contactsMobile,
            callback: function ($$v) {
              _vm.$set(_vm.form, "contactsMobile", $$v);
            },
            expression: "form.contactsMobile"
          }
        }), _vm.form.contactsRemark ? _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "rows": "3",
            "autosize": "",
            "maxlength": "100",
            "type": "textarea",
            "label": "备注",
            "input-align": "right"
          },
          model: {
            value: _vm.form.contactsRemark,
            callback: function ($$v) {
              _vm.$set(_vm.form, "contactsRemark", $$v);
            },
            expression: "form.contactsRemark"
          }
        }) : _vm._e()], 1), _c('div', {
          staticClass: "steps"
        }, [_c('div', {
          staticClass: "steps_header"
        }, [_c('div', [_vm._v("工单处理明细")]), _c('div')]), _c('div', {
          staticClass: "steps_content"
        }, [_c('van-steps', {
          attrs: {
            "direction": "vertical",
            "active": 0,
            "active-color": "#333333"
          }
        }, _vm._l(_vm.form.orderNodeList, function (item) {
          return _c('van-step', {
            key: item.id
          }, [_c('div', {
            staticClass: "steps-item",
            staticStyle: {
              "display": "flex",
              "justify-content": "space-between"
            }
          }, [_c('span', [_vm._v(_vm._s(item.des))]), _c('span', [_vm._v(_vm._s(item.operUserName))])]), _c('p', {
            staticStyle: {
              "color": "#999999"
            }
          }, [_vm._v(_vm._s(item.createTime))])]);
        }), 1)], 1)]), _vm.form.status == 4 ? _c('div', {
          staticClass: "responsibility card house"
        }, [_c('div', {
          staticClass: "card-header"
        }, [_c('div', {
          staticClass: "card-header-title"
        }, [_vm._v("收费明细")]), _c('div', {
          staticClass: "card-header-data"
        }, [_vm.form.payStatus == 1 ? _c('div', {
          staticClass: "total",
          staticStyle: {
            "color": "limegreen"
          }
        }, [_vm._v(" 已支付 ")]) : _c('div', {
          staticClass: "total",
          staticStyle: {
            "color": "red"
          }
        }, [_vm._v(" 未支付 ")])])]), _c('div', {
          staticClass: "card-content"
        }, [_c('div', {
          staticClass: "scroll"
        }, [_c('table', {
          staticClass: "spec_table table"
        }, [_c('thead', [_c('th', [_vm._v("序号")]), _c('th', [_vm._v("物资明细")]), _c('th', [_vm._v("物资数目")]), _c('th', [_vm._v("物资单价")])]), _c('tbody', _vm._l(_vm.shouFeiAllList, function (item, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(item.name))]), _c('td', [_vm._v(_vm._s(item.total))]), _c('td', [_vm._v(_vm._s(item.price))])]);
        }), 0)])]), _c('div', {
          staticClass: "table-total"
        }, [_vm._v("合计："), _c('span', {
          staticClass: "total"
        }, [_vm._v(_vm._s(_vm.disposeTotalShouFei()))])])])]) : _vm._e(), _vm.form.status === 1 ? _c('van-button', {
          staticStyle: {
            "margin": "20px 0"
          },
          attrs: {
            "block": ""
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              return _vm.handleShowReaseOrder.apply(null, arguments);
            }
          }
        }, [_vm._v(" 取消工单 ")]) : _vm._e()], 1)];
      },
      proxy: true
    }])
  }), _c('van-dialog', {
    attrs: {
      "title": "取消工单",
      "show-cancel-button": "",
      "before-close": _vm.reasonBeforeClose
    },
    model: {
      value: _vm.showReasonOrder,
      callback: function ($$v) {
        _vm.showReasonOrder = $$v;
      },
      expression: "showReasonOrder"
    }
  }, [_c('van-field', {
    attrs: {
      "label": "取消原因",
      "type": "textarea",
      "rows": "3",
      "autosize": "",
      "maxlength": "200",
      "placeholder": "请输入",
      "input-align": "left",
      "show-word-limit": ""
    },
    model: {
      value: _vm.orderReasonValue,
      callback: function ($$v) {
        _vm.orderReasonValue = $$v;
      },
      expression: "orderReasonValue"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };