import request from '@utils/request_gd';
export const getListAPI = (params = {}, data) => {
  return request.post('/work/order/getPageOfManager', params);
};
export const getPageOfManager = (params = {}, data) => {
  return request.get('/api/v1/workOrder/getCleanRuleListOfProjectId', {
    params
  });
};

/*
*
*  空间层级
* */
export const getSpaceList = (params = {}, data) => {
  return request.get('/work/space/getList', {
    params
  });
};

/*
*
*  图片上传
* */
export const fileUpload = (file = {}, config) => {
  let data = new FormData();
  console.log(file);
  data.append('file', file.file);
  return request.post('/file/upload', data);
};

/*
*
*  保洁类型
* */
export const getCleanRuleList = (params = {}, data) => {
  return request.post('/api/v1/workOrder/getCleanRuleList', params);
};
export const getNewCleanRuleList = (params = {}, data) => {
  return request.get('/api/v1/workOrder/getCleanRuleList', {
    params
  });
};

/*
*
*  问题类型
* */
export const getQuestionCategoryList = (params = {}, data) => {
  return request.post('/work/order/getQuestionCategoryList', params);
};

/*
*
*  创建工单
* */
export const createOrder = (params = {}, data) => {
  return request.post('/work/order/createOrder', params);
};

/*
*
*  工单详情
* */
export const getDetailById = (params = {}, data) => {
  return request.post('/work/order/getById', params);
};

/*
*
*  保洁记录确认完成
* */
export const confirmComplete = (params = {}, data) => {
  return request.post('/work/order/confirmComplete', params);
};

/*
* 取消工单
* */
export const cancelOrder = (params = {}, data) => {
  return request.post('/work/order/cancelOrder', params);
};
/*
*  收费列表
* @
* */
export const getShouFeiList = (params = {}) => {
  return request.get('/api/v1/workOrder/getShouFeiList', {
    params
  });
};